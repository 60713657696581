.App {
  font-family: "Roboto", sans-serif;
  background-color: #f3f3f3;
  min-height: 100vh;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

.MainHome {
  /* padding-top: 81px; */
  margin-bottom: auto;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 100vw;
}

.MainListings {
  padding-top: 62px;
  margin-bottom: auto;
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  max-width: 100vw;
}

.Content {
  background-color: rgba(255, 255, 255, 0.7);
  padding: 10px;
  border-radius: 2px;
  border: 1px solid #dadadf;
}

.FillerImage {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.Image {
  max-height: 40vh;
  max-width: 100%;
  object-fit: contain;
}

* {
  box-sizing: border-box;
}

a {
  color: #555555;
  transition: color ease-in-out 0.2s;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
  color: #555555;
}

input[type="file"] {
  display: none;
}

figure {
  text-align: center;
  display: inline-block;
}

/* scrollbar */
*::-webkit-scrollbar {
  width: 5px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
}
*::-webkit-scrollbar-thumb {
  background: #888;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Cookie Banner*/

.CookieBannerContent {
  border-radius: 0.3rem;
  font-size: 11px;
  margin-bottom: 1rem;
  color: #fff;
}

.CookieBannerButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.CookieReset {
  position: fixed;
  bottom: 10px;
  right: 10px;
  font-size: 1.5rem;
  border: 1.5px solid #555;
  border-radius: 50%;
  padding: 2px 5px;
  z-index: 900;
  cursor: pointer;
}

.CookieReset:hover {
  background-color: #555;
  color: #fff;
}

/* Responsive */
@media (max-width: 768px) {
  .Main {
    padding-top: 75px;
  }
}
