.BrochureContainer {
    background-color: rgba(77, 103, 101, 0.1);
    box-sizing: border-box;
}

/* .BrochureLogo {
    background-color: rgba(77, 103, 101, 0.1);
} */

.ContainerWrapper {
   position: relative;
}

.FooterPosition {
    position: absolute;
    bottom: -0.5px;
    left: 0px;
}

.OliveBg {
    background-color: rgba(132, 124, 61, 0.8);
}

.TextSize {
    font-size: 2rem;
}

.BrochureDescription {
    font-weight: 400;
    font-size: 1rem;
}

.ImageWrapper,
.Image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0.3rem;
    width: 100%;
    height: 298px;
    margin-bottom: 4px;
}

.Image {
    height: 600px;
}

.ImageMap {
    border-radius: 0.3rem;
    height: 350px;
    width: 600px;
    object-fit: cover;
}

.ImagesContainer {
    position: relative;
}



.Listing__floorValue {
    font-family: 'Font Awesome 6 Free', sans-serif;
    font-weight: 550;
}

.Listing__floorValueEn {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
}

/* Features table */

.Listing__table {
    font-size: 0.8rem;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid rgba(132, 124, 61, 0.5);
    border-radius: 0.3rem;
    overflow: hidden;
    width: 300px;
}

.Listing__table td,
.Listing__table th {
    border: 1px solid rgba(132, 124, 61, 0.5);
    padding: 5px;
}

.Listing__table td:first-child,
.Listing__table th:first-child {
    background-color: #ececec;
    width: 60%;
    border-left: none;
    border-bottom: none;
    border-right: none;
}

.Listing__table th:last-child,
.Listing__table td:last-child {
    border-right: none;
    border-bottom: none;
    font-weight: 500;
}

.Listing__table tr:first-child th,
.Listing__table tr:first-child td {
    border-top: none;
}

.Listing__table tr:last-child th,
.Listing__table tr:last-child td {
    border-bottom: none;
}

.Footer {
    font-size: 1rem;
    height: 70px;
    align-items: center;
}

.link {
    color: #131313;
    transition: color ease-in-out 0.2s;
    text-decoration: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
}