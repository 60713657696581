.Container {
  margin-top: 110px;
  min-height: 343px;
}

.ImageWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.Image {
  max-height: 15vh;
  max-width: 100%;
  object-fit: contain;
  margin: 2px 5px;
}

.ImageUpload:hover {
  cursor: pointer;
}

.SelectedImage {
  border-radius: 5px;
  box-shadow: 0 0 5px 2px #847c3d;
}

.Modal {
  background-color: rgba(215, 215, 215, 0.5);
  display: flex;
  justify-content: center;
}
