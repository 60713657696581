/* Listings Order */
.CustomDropdown {
    width: fit-content;
}

.DropdownToggle {
    background-color: #fff;
    cursor: pointer;
    font-size: 16px;
    text-align: center;
    border: 1px solid rgba(77, 103, 101, 1);
    border-radius: 4px;
    color: rgba(77, 103, 101, 1);
    padding: 2px 15px;
}

.DropdownMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 200px;
    border: 1px solid rgba(77, 103, 101, 1);
    border-bottom: none;
    border-radius: 4px;
    overflow: hidden;
    z-index: 1000;
    right: 0;
}

.DropdownItem {
    background-color: transparent;
    transition: background-color 0.3s;
    border-bottom: 1px solid rgba(77, 103, 101, 1);
}

.DropdownItem:hover {
    background-color: rgba(77, 103, 101, 0.4) !important;
}