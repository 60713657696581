.NavBar {
  background-color: rgba(215, 223, 231, 0);
  max-width: 100vw;
}

.NavBar navbar-toggler {
  background-color: rgba(215, 223, 231, 0.1);
}

.NavLogo {
  width: 192px;
}

.NavBarScroll {
  background-color: #f3f3f3;
  max-width: 100vw;
}

.NavLink {
  padding: 8px 5px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.8);
}

.Navdropdown {
  font-weight: 500;
}

.NavDropdownItem:hover {
  background-color: rgba(132, 124, 61, 0.4);
}

.NavLink:hover {
  color: #847c3d;
}

.Active {
  color: #847c3d;
  font-weight: 700;
}

.flecGrow {
  flex-grow: 0;
}

.NavBarMobile {
  background-color: rgba(243, 243, 243, 1);
  max-width: 100vw;
}

.LngBtnContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: 10px;
}

.LngBtnGR {
  background-image: url("../assets/el.svg");
  background-repeat: no-repeat;
  object-fit: cover;
  margin-right: 3px;
}

.LngBtnEN {
  background-image: url("../assets/gb.svg");
  background-repeat: no-repeat;
  object-fit: cover;
}

.LngBtn {
  background-color: rgba(215, 223, 231, 0);
  border: none;
  cursor: pointer;
  width: 18px;
  height: 15px;
}

.LngBtn:hover {
  background-color: rgba(215, 223, 231, 0.3);
}

.LngBtnActive {
  background-color: rgba(215, 223, 231, 0.3);
}

/*Responsive*/
@media (max-width: 767.9px) {
  .Navdropdown {
    padding-left: 5px;
  }
}
