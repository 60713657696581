.Admin {
  margin-top: 110px;
}

.OwnerList>li:hover {
  background-color: rgba(77, 103, 101, 0.45);
  cursor: pointer;
}

.FileUploadContainer {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed rgba(77, 103, 101, 0.6);
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  background-color: #f8f9fa;
  transition: background-color 0.3s ease;
}

.FileUploadContainer:hover {
  background-color: #e9ecef;
}

.FileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.FileUploadLabel {
  text-align: center;
  color: rgba(77, 103, 101, 1);
  font-weight: bold;
  z-index: 1;
  pointer-events: none;
  /* Ensures clicks pass through to the hidden input */
}
