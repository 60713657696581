.DropdownItem {
  display: inline-block;
  max-width: 50%;
  padding: 0px;
}

.Absolute {
  position: absolute;
  right: 0;
  z-index: 99;
}

.DropdownCaret {
  cursor: pointer;
}
