.MunicipalitySearch {
    font-size: 1rem;
    border: 1px solid #4d6765;
}

.MunicipalityDropdownSearch {
    position: absolute;
    background-color: white;
    border: 1px solid #4d6765;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: fit-content;
    z-index: 1000;
}


.MunicipalitySearch:focus {
    box-shadow: 0 0 0 .15rem rgba(77, 103, 101, 0.45) !important;
    border-color: rgba(132, 124, 61, 0.4) !important;
}

.MunicipalityDropdownSearch li {
    padding: 8px 12px;
    cursor: pointer;
    font-size: 1rem;
}

.MunicipalityDropdownSearch li:hover {
    background-color: rgba(132, 124, 61, 0.4);
}

.SelectedItem {
    background-color: rgba(132, 124, 61, 0.4);
    cursor: pointer;
}