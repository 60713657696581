/* * {
    box-sizing: border-box;
    border: 1px solid #f10505;
} */
 

.MunicipalityDropdownSearch {
    position: absolute;
    max-height: 200px;
    padding: 0;
    margin: 0;
    width: fit-content;
    z-index: 1000;
    font-size: 0.8rem;
}

.MunicipalityDropdownSearch li {
    list-style-type: none;
    cursor: pointer;
    font-size: 0.8rem;
}

.MunicipalityDropdownSearch li:hover {
    background-color: #e9e7e7;
}
